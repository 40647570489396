<template>
  <page class="container">
    <v-header></v-header>
    <div class="no-scroll-container">
      <!--头部-->
      <div class="record-header">
        <div class="record-header-item">
          <div class="record-header-title">
            <div>
              <div>{{ currTab.text }}</div>
              <div class="record-header-title-underline"></div>
            </div>
          </div>
        </div>
        <div class="record-header-item">
          <div></div>
          <div class="select-item">
            <v-select v-if="!$route.query.type" :option="recordTabs" :title="currTab.text" @change="val=>currTab = val"></v-select>
          </div>
        </div>
      </div>
      <div class="top-bar">
        <div class="statistic">
          <div class="statistic-item" v-if="currTab.value === 'RECHARGE'">
            <div class="statistic-label">合计</div>
            <div class="statistic-value">¥{{moneyFilter(statistic.sumRecharge)}}</div>
          </div>
        </div>
        <div class="focus-tip" @click="$refs.gzhDialog.show()">想第一时间收到{{ currTab.text }}？</div>
      </div>

      <!--滑动区域-->
      <!--奖励记录-->
      <div class="record-li" v-show="currTab.value === 'TASK'">
        <van-pull-refresh v-model="taskAward.refreshing" @refresh="loadData(taskAward,true)">
          <van-list v-model="taskAward.loading" @load="loadData(taskAward,false)" :finished="!taskAward.hasMore"
                    :immediate-check="false">
            <div v-for="(item,index) in taskAward.records">
              <div class="record-date" v-if="item.isMonth">{{ item.month }}</div>
              <div :class="'record-item '+(index===taskAward.records.length-1?'last':'')" v-else>
                <div class="flex-vertical">
                  <img src="https://cdn.001ppt.cn/h5/assets/svg/profit.svg" alt="" class="record-it-icon">
                  <div class="record-it-main">
                    <div class="record-it-remark">{{ item.taskName }}</div>
                    <div class="record-it-date">{{ formatDate(item.createTime) }}</div>
                  </div>
                </div>
                <div class="record-it-yanzhi">+{{ item.taskYanzhi }}严值</div>
              </div>
            </div>
            <div class="bottom-text" v-if="!taskAward.hasMore">
              <div v-if="taskAward.records && taskAward.records.length">灵感到底了~</div>
              <div v-else
                   class="no-data-box">
                <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150"
                     style="position: relative;top: -100px;">
                <span class="no-data-text">噢！这可能有点儿空 (⊙v⊙)</span>
              </div>
            </div>
            <div class="bottom-text" v-else-if="taskAward.loading">加载中</div>
            <div class="bottom-text" v-else>加载更多</div>
          </van-list>
        </van-pull-refresh>
      </div>

      <!--收益记录-->
      <div class="record-li" v-show="currTab.value === 'PROFIT'">
        <van-pull-refresh v-model="profit.refreshing" @refresh="loadData(profit,true)">
          <van-list v-model="profit.loading" @load="loadData(profit,false)" :finished="!profit.hasMore"
                    :immediate-check="false">
            <div v-for="(item,index) in profit.records" :key="index">
              <div class="record-date" v-if="item.isMonth">{{ item.month }}</div>
              <div :class="'record-item '+(index===profit.records.length-1?'last':'')" v-else>
                <div class="flex-vertical">
                  <img src="https://cdn.001ppt.cn/h5/assets/svg/profit.svg" alt="" class="record-it-icon">
                  <div class="record-it-main">
                    <div class="record-it-remark">{{ item.taskName }}</div>
                    <div class="record-it-date">{{ formatDate(item.createTime) }}</div>
                  </div>
                </div>
                <div class="record-it-yanzhi">+￥{{ item.profitYanzhi/10 }}</div>
              </div>
            </div>
            <div class="bottom-text" v-if="!profit.hasMore">
              <div v-if="profit.records && profit.records.length">灵感到底了~</div>
              <div v-else
                   class="no-data-box">
                <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150"
                     style="position: relative;top: -100px;">
                <span class="no-data-text">噢！这可能有点儿空 (⊙v⊙)</span>
              </div>
            </div>
            <div class="bottom-text" v-else-if="profit.loading">加载中</div>
            <div class="bottom-text" v-else>加载更多</div>
          </van-list>
        </van-pull-refresh>
      </div>

      <!--充值记录-->
      <div class="record-li" v-show="currTab.value === 'RECHARGE'">
        <van-pull-refresh v-model="recharge.refreshing" @refresh="loadData(recharge,true)">
          <van-list v-model="recharge.loading" @load="loadData(recharge,false)" :finished="!recharge.hasMore"
                    :immediate-check="false"
          >
            <div v-for="(item,index) in recharge.records">
              <div class="record-date" v-if="item.isMonth">{{ item.month }}</div>
              <div :class="'record-item '+(index===recharge.records.length-1?'last':'')" v-else>
                <div class="flex-vertical">
                  <img src="https://cdn.001ppt.cn/h5/assets/svg/recharge.svg" alt="" class="record-it-icon">
                  <div class="record-it-main">
                    <div class="record-it-remark">
                      <template v-if="item.consumeType===3">
                        已充值{{ item.rechargeYanzhi || (yanzhiFilter(item.consumeValue * 10)) }}严值
                        <template v-if="item.rechargeSource===1">（优惠券发放）</template>
                      </template>
                      <template v-else-if="item.consumeType===2">加入会员</template>
                    </div>
                    <div class="record-it-date">{{ formatDate(item.createTime) }}</div>
                  </div>
                </div>
                <div class="record-it-yanzhi">
                  <template v-if="item.rechargeSource === 1">
                    + ¥0
                  </template>
                  <template v-else>
                    + ¥{{ item.consumeValue }}
                  </template>
                </div>
              </div>
            </div>
            <div class="bottom-text" v-if="!recharge.hasMore">
              <div v-if="recharge.records && recharge.records.length">灵感到底了~</div>
              <div v-else
                   class="no-data-box">
                <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150"
                     style="position: relative;top: -100px;">
                <span class="no-data-text">噢！这可能有点儿空 (⊙v⊙)</span>
              </div>
            </div>
            <div class="bottom-text" v-else-if="recharge.loading">加载中</div>
            <div class="bottom-text" v-else>加载更多</div>
          </van-list>
        </van-pull-refresh>
      </div>

      <!--消费记录-->
      <div class="record-li" v-show="currTab.value === 'CONSUME'">
        <van-pull-refresh v-model="consume.refreshing" @refresh="loadData(consume,true)">
          <van-list v-model="consume.loading" @load="loadData(consume,false)" :finished="!consume.hasMore"
                    :immediate-check="false"
          >
            <div v-for="(item,index) in consume.records">
              <div class="record-date" v-if="item.isMonth">{{ item.month }}</div>
              <div :class="'record-item '+(index===recharge.records.length-1?'last':'')" v-else>
                <div class="flex-vertical">
                  <img src="https://cdn.001ppt.cn/h5/assets/svg/consume.svg" alt="" class="record-it-icon">
                  <div class="record-it-main">
                    <div class="record-it-remark">{{ item.consumeTypeName }}
                      <template v-if="item.consumeYanzhiValue && (item.consumeType==5 || item.consumeType==2)">
                        （含{{ item.consumeYanzhiValue }}严值抵扣）
                      </template>
                      <div v-else-if="item.hasRefund">(原方案已下架，已原路退款)</div>
                    </div>
                    <div class="record-it-date">{{ formatDate(item.createTime) }}</div>
                  </div>
                </div>
                <div class="record-it-yanzhi">-¥{{ item.consumeValue }}</div>
              </div>
            </div>
            <div class="bottom-text" v-if="!consume.hasMore">
              <div v-if="consume.records && consume.records.length">灵感到底了~</div>
              <div v-else
                   class="no-data-box">
                <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150"
                     style="position: relative;top: -100px;">
                <span class="no-data-text">噢！这可能有点儿空 (⊙v⊙)</span>
              </div>
            </div>
            <div class="bottom-text" v-else-if="consume.loading">加载中</div>
            <div class="bottom-text" v-else>加载更多</div>
          </van-list>
        </van-pull-refresh>
      </div>

      <!--提现记录-->
      <div class="record-li" v-show="currTab.value === 'CASH_OUT'">
        <van-pull-refresh v-model="cashOut.refreshing" @refresh="loadData(cashOut,true)">
          <van-list v-model="cashOut.loading" @load="loadData(cashOut,false)" :finished="!cashOut.hasMore"
                    :immediate-check="false"
          >
            <div v-for="(item,index) in cashOut.records">
              <div class="record-date" v-if="item.isMonth">{{ item.month }}</div>
              <div :class="'record-item '+(index===cashOut.records.length-1?'last':'')" v-else>
                <div class="flex-vertical">
                  <img src="https://cdn.001ppt.cn/h5/assets/svg/cash_out.svg" alt="" class="record-it-icon">
                  <div class="record-it-main">
                    <template>
                      <div class="record-it-remark" v-if="item.status === 0">收益提现（审核中）</div>
                      <div class="record-it-remark" v-else-if="item.status === 1">收益提现（已提现）</div>
                      <div class="record-it-remark" v-else-if="item.status === 10">
                        <div>收益提现（已驳回）</div>
                        <div>{{ item.rejectReason }}</div>
                      </div>
                    </template>
                    <div class="record-it-date">{{ formatDate(item.createTime) }}</div>
                  </div>
                </div>
                <div class="record-it-yanzhi">- ￥{{ moneyFilter(item.cashOutYanzhi / 10) }}</div>
              </div>
            </div>
            <div class="bottom-text" v-if="!cashOut.hasMore">
              <div v-if="cashOut.records && cashOut.records.length">灵感到底了~</div>
              <div v-else
                   class="no-data-box">
                <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150"
                     style="position: relative;top: -100px;">
                <span class="no-data-text">噢！这可能有点儿空 (⊙v⊙)</span>
              </div>
            </div>
            <div class="bottom-text" v-else-if="cashOut.loading">加载中</div>
            <div class="bottom-text" v-else>加载更多</div>
          </van-list>
        </van-pull-refresh>
      </div>

    </div>
    <gzh-dialog :type="1" ref="gzhDialog"/>
  </page>
</template>

<script>
import {user} from "@/api";
import vScroll from '@/components/scroll'
import vSelect from '@/components/select'
import {formatDate} from "../../filters/dateFilter";
import {sleep} from "../../config/util";
import {cashOutV2Api} from "../../api/v2/cashOutV2Api";
import GzhDialog from '@/components/GzhDialog'
import {consumeRecordV2Api} from "../../api/v2/consumeRecordV2Api";
import {taskV2Api} from "../../api/v2/taskV2Api";


export default {
  components: {vScroll, vSelect, GzhDialog},
  data() {
    return {
      statistic:{
        sumRecharge: 0
      },
      recordTabs: [
        {text: '奖励记录', value: 'TASK', key: 'taskAward'},
        {text: '收益记录', value: 'PROFIT', key: 'profit'},
        {text: '充值记录', value: 'RECHARGE', key: 'recharge'},
        {text: '消费记录', value: 'CONSUME', key: 'consume'},
        {text: '提现记录', value: 'CASH_OUT', key: 'cashOut'},
      ],
      currTab: {text: '', value: ''},
      active: 0,
      taskAward: {
        current: 0,
        pageSize: 20,
        records: [],
        refreshing: false,
        loading: false,
        hasMore: true,
        api: taskV2Api.findMyAwardPage
      },
      profit: {
        current: 0,
        pageSize: 20,
        records: [],
        refreshing: false,
        loading: false,
        hasMore: true,
        api: taskV2Api.findMyProfitPage
      },
      recharge: {
        current: 0,
        pageSize: 20,
        records: [],
        refreshing: false,
        loading: false,
        hasMore: true,
        api: consumeRecordV2Api.findMyPayRecordPage
      },
      consume: {
        current: 0,
        pageSize: 20,
        records: [],
        refreshing: false,
        loading: false,
        hasMore: true,
        api: user.consume2
      },
      cashOut: {
        current: 0,
        pageSize: 20,
        records: [],
        refreshing: false,
        loading: false,
        hasMore: true,
        api: cashOutV2Api.findMyPage2
      }
    };

  },

  created() {
    try {
      this.active = parseInt(this.$route.query.type) - 1;
    } catch (e) {
      this.active = 0;
    }
  },


  mounted() {
    // 初始化tab
    const {tab} = this.$route.query;
    let tabIndex = 0;
    if (tab) {
      tabIndex = this.recordTabs.findIndex(t => t.value === tab);
      tabIndex = tabIndex || 0;
    }
    this.currTab = this.recordTabs[tabIndex];
    this.loadStatistic();
  },


  watch: {
    currTab() {
      const key = this.currTab.key;
      const model = this[key];
      if (model.current === 0) {
        this.loadData(model, true);
      }
    }
  },


  methods: {


    async loadStatistic(){
      this.statistic.sumRecharge = await consumeRecordV2Api.findMyPayTotal()
    },


    // 加载数据
    async loadData(model, isRefresh) {
      let current = 1;
      let {pageSize} = model;
      if (isRefresh) {
        model.refreshing = true;
      } else {
        model.loading = true;
        current = model.current + 1;
      }
      model.current = current;

      try {
        const res = await model.api({current, pageSize, size: pageSize,...this.$route.query});
        model.current = res.current;
        model.pageSize = res.pageSize || res.size;
        model.hasMore = res.total > model.pageSize * model.current;

        // 处理records
        let records = isRefresh ? [] : model.records;
        for (const row of res.records) {
          const last = records[records.length - 1];
          const lastMonth = last ? formatDate(last.createTime, 'yyyy年MM月') : '';
          const currMonth = formatDate(row.createTime, 'yyyy年MM月');
          if (lastMonth !== currMonth) {
            records.push({month: currMonth, isMonth: true})
          }
          records.push({isMonth: false, ...row})
        }
        model.records = records;
      } catch (e) {
        model.current--;
      } finally {
        model.loading = false;
        await sleep(300);
        model.refreshing = false;
      }
    }

  }
}
</script>

<style scoped lang="less">
.record-header {
  height: 13.33vw;
  display: flex;
}

.record-header-item {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 28px;
  font-weight: bolder;
}

.record-header-title {
  display: flex;
  align-items: center;
  height: 100%;
}

.record-header-title-underline {
  width: 20px;
  height: 3px;
  background: #333;
  transform: translatey(2vw);
  margin: 0 auto;
}

.select-item {
  font-weight: lighter;
}

::v-deep .popover-title * {
  color: #505050;
}

.record-li {
  height: calc(100vh - 110px);
  background: #f7f7f7;
  overflow-y: scroll;
  padding: 24px 27px;
}

.record-date {
  color: #151d36;
  font-weight: bolder;
  padding-bottom: 18px;
}

.record-item {
  padding-bottom: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.last {
    padding-bottom: 0;
  }
}

.record-it-icon {
  height: 26px;
  width: 26px;
}

.record-it-main {
  padding-left: 8px;
}

.record-it-remark {
  font-size: 12px;
}

.record-it-date {
  font-size: 10px;
  color: #151d36;
  opacity: 0.3;
  padding-top: 4px;
}

.record-it-yanzhi {
  width: 99px;
  text-align: right;
  font-size: 20px;
  color: #151d36;
  font-weight: bolder;
}

.no-data-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative
}

.no-data-box .no-data-text {
  position: relative;
  top: -100px;
  margin-top: 10px;
  color: #999;
  font-size: 12px;
  opacity: .25;
}

.bottom-text {
  color: #999;
  text-align: center;
  padding: 16px 0;
}


.no-data-box {
  margin: 200px 0;
}

.focus-tip {
  color: var(--grey-color);
  font-size: 12px;
  text-align: right;
  background: #f7f7f7;
}

.top-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 22px 0 22px;
  background: #f7f7f7;
}

.statistic{
  display: flex;
  align-items: center;
}

.statistic-item{
  display: flex;
  align-items: center;
}

.statistic-label{
  font-weight: bolder;
  padding-right: 8px;
}
</style>
